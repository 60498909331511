import "../components/elements/multi-select/multi-select.js";
import "../components/elements/slider/slider.js";
import "../components/template-components/catalogue/article-list/filter/filter.js";
import "../components/template-components/catalogue/article-view/article-view.js";
import "../components/template-components/catalogue/article-list/article-list.js";
import "../components/template-components/search-overlay/search-overlay.js";

/**
 * Bugfix
 *
 * We do have button links inside summary elements. These button links
 * contain a span (.Button-label). In Safari, when the user clicks
 * on these spans instead of the anchor (they are able to click on the anchor
 * because of some padding), the toggle functionality of the details/summary
 * elements is triggered instead of following the link.
 * We therefore prevent the default behavior after a click on all .Button-label
 * inside a summary element and programmatically trigger the redirect.
 */
document.querySelectorAll("summary a .Button-label").forEach((buttonLabel) => {
	buttonLabel.addEventListener("click", (e) => {
		e.preventDefault();

		const link = e.target.closest("a");

		window.location.href = link.href;
	});
});

document.querySelectorAll(".Button.has-spinner").forEach((button) => {
	const form = button.closest("form");

	if (form) {
		form.addEventListener("submit", () => {
			button.disabled = true;
			button.classList.add("is-loading");
		});
	}
});

/**
 * Adds the Google Analytics script to the document head.
 *
 */
function addGoogleAnalyticsScript() {
	const script1 = document.createElement('script');
	script1.setAttribute('async', '');
	script1.src = "https://www.googletagmanager.com/gtag/js?id=G-L5JX1XKX7W";
	document.head.appendChild(script1);

	const script2 = document.createElement('script');
	script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-L5JX1XKX7W');
    `;
	document.head.appendChild(script2);
}

function checkAndLoadAnalytics() {
	if (Cookiebot.consent.marketing) {
		addGoogleAnalyticsScript();
	}
}

// Check if the marketing consent is already given when the page loads
window.addEventListener("load", function() {
	checkAndLoadAnalytics();
});

// Use Cookiebot's callback to listen for accept in consent
window.addEventListener("CookiebotOnAccept", function() {
	checkAndLoadAnalytics();
});

// Use Cookiebot's callback to listen for changes in consent
window.addEventListener("CookieConsentUpdate", function() {
	checkAndLoadAnalytics();
});
